import React, { useEffect, useState } from "react"
import axios from "axios"

function Academy(){

  const ACADEMY_API_URL = `https://nsmatrixbasketball.com/nsmatrix/api/academy`
  const [overviewText, setOverviewText] = useState('')
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    getInfo()
}, [flag]);

  function getInfo() {
    axios.get(`${ACADEMY_API_URL}/read.php`).then((res) => {
        setOverviewText(res.data[0]['overview_text'])
        setFlag(true)
    }, (error) => {
    })
}

    return(
        <div className="sportsmagazine-main-section sportsmagazine-bloglarge-full">
        <div className="container">
          <div className="row">
            <div className>
              <div className="sportsmagazine-blog sportsmagazine-bloglarge">
                <ul className="row">
                  <li className="col-md-12">
                    <figure><img src={require('../assets/images/NS_Matrix_website_logo-preview.png')} alt="" /></figure>
                    <div className="sportsmagazine-bloglarge-text">
                      <p>
                       {overviewText}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="sportsmagazine-gallery grid sportsmagazine-modern-gallery">
                <ul className="row">
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy1.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy1.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy2.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy2.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy3.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy3.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy4.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy4.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy5.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy5.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy6.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy6.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy7.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy7.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy8.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy8.jpg')} alt="" /></a>
                      <span />
                      <figcaption>
                        <h6><a href="#">Sed gravida, urna tempus sollicitudin, tellus</a></h6>
                        <time dateTime="2008-02-14 20:00">August 21st 2017</time>
                      </figcaption>
                    </figure>
                  </li>
                  <li className="col-md-4 grid-item">    
                    <figure>
                      <a data-fancybox-group="group" href={require('../assets/extra-images/academy9.jpg')} className="fancybox"><img src={require('../assets/extra-images/academy9.jpg')} alt="" /></a>
                      <span />
                    </figure>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Academy;